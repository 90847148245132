@import url("https://fonts.googleapis.com/css2?family=Bungee+Shade&display=swap");
.project {
  padding: 65px 40px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  -ms-grid-rows: max-content 1fr;
      grid-template-rows: -webkit-max-content 1fr;
      grid-template-rows: max-content 1fr;
  -webkit-column-gap: 60px;
          column-gap: 60px;
  background-color: #3d3d52;
  border-radius: 8px;
  position: relative;
  margin-bottom: 200px;
}

.project::before {
  content: attr(data-index);
  font-size: 130px;
  line-height: 1em;
  position: absolute;
  z-index: -2;
  right: -40px;
  top: 0;
  -webkit-transform: translateY(-90%);
          transform: translateY(-90%);
  color: #6c6c7a;
  font-family: "Bungee Shade", cursive;
}

.project .image {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-column: 1 / 2;
  -ms-grid-row: 1;
  -ms-grid-row-span: 2;
  grid-row: 1 / 3;
  border-radius: 10px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.project .image::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.81);
  z-index: 2;
  -webkit-transition: opacity 0.4s;
  transition: opacity 0.4s;
  opacity: 0;
}

.project .image img {
  width: 100%;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transition: -webkit-transform 0.4s;
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
}

.project .image svg {
  position: absolute;
  display: inline-block;
  top: 45%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-transition: opacity 0.4s;
  transition: opacity 0.4s;
  opacity: 0;
  z-index: 3;
  width: 30px;
}

/* .project .image:hover::before {
  opacity: 1;
} */

.project .image:hover img {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.project .image:hover svg {
  opacity: 1;
}

.project .top {
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2 / 3;
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  grid-row: 1 / 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.project .top p {
  color: #fff;
  font-size: 17px;
  font-weight: 700;
  text-transform: uppercase;
}

.project .top .icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 20px;
}

.project .top .icons a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.project .top .icons svg {
  width: 20px;
}

.project .description {
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2 / 3;
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  grid-row: 2 / 3;
}

.project .description > p {
  color: #f0f0f4;
  margin-top: 10px;
  font-size: 15px;
}

.project .description .list_area {
  margin-top: 20px;
}

.project .description .grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  margin-bottom: 4px;
  font-size: 14px;
}

.project .description .grid .label {
  font-weight: 600;
  color: #fff;
}

.project .description .tag {
  font-weight: 400;
  font-size: 12px;
  background-color: gray;
  border: 1px solid rgb(221, 217, 217);
  padding: 2px 7px;
  border-radius: 10px;
  margin: 2px 2px;
  color: #fff;
}

.project .description .grid .list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 500;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-column-gap: 10px;
          column-gap: 10px;
  row-gap: 5px;
  color: #b9b9cb;
  font-family: "Roboto Mono", monospace;
}

.project:nth-child(even) .image {
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2/ 3;
}

.project:nth-child(even) .top,
.project:nth-child(even) .description {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-column: 1 / 2;
}

.project:nth-child(even)::before {
  right: unset;
  left: -40px;
}

.project:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 800px) {
  .project {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 25px;
    margin-bottom: 55px;
  }
  .project::before {
    display: none;
  }
  .project .description > p {
    margin: 0;
  }
}

@media screen and (max-width: 580px) {
  .project {
    padding: 20px 20px;
  }
}
