.item {
  border-radius: 50%;
  width: var(--size);
  height: var(--size);
  font-size: var(--font-size);
  position: absolute;
  background-color: #2f2f37;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
  text-align: center;
}

.item:hover figure {
  opacity: 1;
}

.item:hover figure svg {
  -webkit-transform: scale(1);
          transform: scale(1);
}

.item figure {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  opacity: 0;
  background-color: #fffd77;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
  border-radius: 50%;
}

.item figure svg {
  width: 40px;
  -webkit-transform: scale(0.7);
          transform: scale(0.7);
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
}

.item:last-child figure svg {
  width: 30px;
}

@media screen and (max-width: 1130px) {
  .item {
    position: static;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    text-transform: capitalize;
    cursor: initial;
    border-radius: 0;
    background-color: transparent;
    width: unset;
    height: unset;
    row-gap: 10px;
  }
  .item figure {
    position: static;
    --size: 70px;
    width: var(--size);
    height: var(--size);
    background-color: #2f2f37;
    opacity: 1;
    -webkit-transition: none;
    transition: none;
  }
  .item figure svg {
    -webkit-transform: none;
            transform: none;
    width: 25px;
  }
  .item figure svg path {
    fill: #fff;
  }
  .item p {
    font-size: 14px;
    font-weight: 400;
  }
}
