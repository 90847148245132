@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400&family=Karla:ital,wght@0,400;0,700;1,400;1,700");
html {
  scroll-behavior: smooth;
}

body {
  font-family: "Karla", sans-serif;
  font-weight: bold;
  font-size: large;
}

/* @import url("https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400&family=Karla:ital,wght@0,400;0,700;1,400;1,700");
html {
  scroll-behavior: smooth;
}

body {
  font-family: "Karla", sans-serif;
  font-weight: bold;
  font-size: large;
} */


*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 13px;
  border-radius: 15px;
}

::-webkit-scrollbar-track {
  background-color: #202324;
}

::-webkit-scrollbar-thumb {
  background-color: #454a4d;
  border-radius: 15px;
}

.hero {
  height: 100vh;
  position: relative;
  background-color: #181820;
  padding: 40px 70px;
}

.hero .grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  -webkit-column-gap: 30px;
          column-gap: 30px;
}

.hero .grid h1 {
  color: #fff;
  font-size: 35px;
}

.hero .grid h1 span {
  color: #7c99df;
}

.hero .grid .subtitle {
  color: #c5c5d3;
  margin: 25px 0;
  font-size: 18px;
  max-width: 80%;
}

.hero .grid .image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.hero .grid figure {
  max-width: 450px;
}

.hero .grid figure svg {
  max-width: 100%;
}

@media screen and (max-width: 970px) {
  .hero .grid {
    display: block;
  }
  .hero .grid .image {
    display: none;
  }
}

@media screen and (max-width: 580px) {
  .hero {
    padding: 25px;
  }
  .hero .grid h1 {
    font-size: 28px;
  }
  .hero .grid .subtitle {
    max-width: unset;
  }
}

.about {
  padding: 105px 120px;
  padding-bottom: 80px;
  background-color: #303040;
  overflow: hidden;
}

.about .grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  -webkit-column-gap: 40px;
          column-gap: 40px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.about .grid .about-talk {
  color: #b9b9cb;
  margin-top: 15px;
  max-width: 500px;
}

.about .grid .about-talk em {
  color: #f0f0f4;
  font-style: normal;
}

@media screen and (max-width: 1280px) {
  .about .grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 100px;
  }
}

@media screen and (max-width: 580px) {
  .about {
    padding: 80px 25px;
  }
}

.services {
  position: relative;
  padding: 105px 120px;
  background-color: #303040;
  overflow: hidden;
}

.services h2 svg {
  display: inline-block;
  -webkit-animation: rotate 4s linear infinite;
          animation: rotate 4s linear infinite;
}

.services .watermark {
  position: absolute;
  top: 10%;
  max-width: 800px;
  right: -10%;
}

@-webkit-keyframes rotate {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotate {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@media screen and (max-width: 830px) {
  .services .watermark {
    display: none;
  }
}

@media screen and (max-width: 580px) {
  .services {
    padding: 80px 25px;
    padding-top: 0;
  }
}

.stack {
  height: 100vh;
  background-color: #181820;
  padding: 30px 70px;
  padding-top: 60px;
  position: relative;
  z-index: 1;
}

.stack .grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  -webkit-column-gap: 40px;
          column-gap: 40px;
  height: 490px;
}

.stack .grid .talk {
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
}

.stack .grid .talk h2 {
  color: #fff;
  margin-bottom: 10px;
}

.stack .grid .talk p {
  color: #b9b9cb;
  max-width: 400px;
}

.stack .grid .item-container {
  position: relative;
}

.stack .watermark {
  position: absolute;
  bottom: 0;
  font-size: 70px;
  color: rgba(255, 255, 255, 0.05);
  left: -40px;
  font-weight: 700;
  max-width: 500px;
  line-height: 1.5em;
  pointer-events: none;
  z-index: -1;
}

@media screen and (max-width: 1130px) {
  .stack .grid {
    display: block;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
    height: unset;
  }
  .stack .item-container {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (1fr)[3];
        grid-template-columns: repeat(3, 1fr);
    -webkit-column-gap: 20px;
            column-gap: 20px;
    row-gap: 40px;
    margin-top: 80px;
    border-left: 2px solid #fffd77;
    padding-left: 20px;
  }
}

@media screen and (max-width: 580px) {
  .stack {
    height: unset;
    min-height: 100vh;
    padding: 40px 25px;
    padding-bottom: 80px;
  }
  .stack .grid {
    margin: 0;
    width: 100%;
  }
  .stack .watermark {
    font-size: 50px;
  }
}

.projects {
  background-color: #303040;
  padding: 50px 70px;
  overflow: hidden;
}

.projects .talk {
  max-width: 400px;
  color: #b9b9cb;
  margin-top: 13px;
}

.projects .projects-container {
  margin-top: 70px;
  /*  
        Just to give stacking context so that
        the ::before of the projects work
    */
  position: relative;
  z-index: 1;
}

.projects .btn {
  margin-top: 40px;
}

.projects .currently {
  color: #b9b9cb;
  max-width: 480px;
  margin-top: 30px;
}

@media screen and (max-width: 580px) {
  .projects {
    padding: 25px;
    padding-bottom: 60px;
  }
  .projects .currently {
    margin-top: 65px;
  }
}

.contact {
  background-color: #181820;
  padding: 80px 70px;
  position: relative;
}

.contact .grid {
  margin-top: 15px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  -webkit-column-gap: 40px;
          column-gap: 40px;
  color: #b9b9cb;
}

.contact .grid .info > p {
  max-width: 380px;
}

.contact .grid .info .info-items {
  margin-top: 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  row-gap: 20px;
}

.contact .grid form .form-grid {
  all: unset;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: max-content max-content;
      grid-template-columns: -webkit-max-content -webkit-max-content;
      grid-template-columns: max-content max-content;
  -ms-grid-rows: 25px 1fr;
      grid-template-rows: 25px 1fr;
  -webkit-column-gap: 60px;
          column-gap: 60px;
  row-gap: 40px;
}

.contact .grid form .form-grid div:last-child {
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-column: 1 / 3;
}

.contact .grid form button {
  margin-top: 40px;
  padding: 8px 50px;
}

.contact .position {
  position: absolute;
  left: 30px;
  bottom: 0;
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
  width: 220px;
}

@media screen and (max-width: 900px) {
  .contact .inner {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
  }
  .contact .grid {
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    row-gap: 100px;
  }
  .contact .position {
    display: none;
  }
}

@media screen and (max-width: 580px) {
  .contact {
    padding: 80px 25px;
  }
  .contact .grid form .form-grid {
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
    -ms-grid-rows: 25px 25px 1fr;
        grid-template-rows: 25px 25px 1fr;
    row-gap: 70px;
  }
  .contact .grid form .form-grid div {
    -ms-grid-column: 1;
    -ms-grid-column-span: 2;
    grid-column: 1 / 3;
  }
}

.footer {
  background-color: #181820;
  padding: 50px 30px;
}

.footer .caption {
  letter-spacing: 0.18em;
  font-size: 22px;
  color: #b9b9cb;
  text-align: center;
}

.footer .caption span {
  font-weight: 700;
  color: #fff;
}

.footer hr {
  margin: 50px auto;
  width: 90%;
  border: none;
  border-top: 1px solid #fff;
}

.footer .credits {
  color: #b9b9cb;
  text-align: center;
  letter-spacing: 0.07em;
}

.footer .icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 35px;
  margin-top: 50px;
}

.footer .icons svg {
  width: 20px;
  height: 20px;
}

@media screen and (max-width: 580px) {
  .footer {
    padding: 50px 18px;
  }
  .footer .caption {
    font-size: 20px;
  }
  .footer hr {
    margin: 40px auto;
  }
}
