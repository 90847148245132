.projects {
  background-color: #303040;
  padding: 50px 70px;
  overflow: hidden;

  .talk {
    max-width: 400px;
    color: #b9b9cb;
    margin-top: 13px;
  }

  .projects-container {
    margin-top: 70px;
    /*  
        Just to give stacking context so that
        the ::before of the projects work
    */

    position: relative;
    z-index: 1;
  }

  .btn {
    margin-top: 40px;
  }

  .currently {
    color: #b9b9cb;
    max-width: 480px;
    margin-top: 30px;
  }
}

@media screen and(max-width:580px) {
  .projects {
    padding: 25px;
    padding-bottom: 60px;
    .currently {
      margin-top: 65px;
    }
  }
}
