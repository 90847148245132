.stack {
  height: 100vh;
  background-color: #181820;
  padding: 30px 70px;
  padding-top: 60px;
  position: relative;
  z-index: 1;

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 40px;
    height: 490px;

    .talk {
      align-self: center;
      h2 {
        color: #fff;
        margin-bottom: 10px;
      }
      p {
        color: #b9b9cb;
        max-width: 400px;
      }
    }

    .item-container {
      position: relative;
    }
  }

  .watermark {
    position: absolute;
    bottom: 0;
    font-size: 70px;
    color: rgba($color: #fff, $alpha: 0.05);
    left: -40px;
    font-weight: 700;
    max-width: 500px;
    line-height: 1.5em;
    pointer-events: none;
    z-index: -1;
  }
}

@media screen and(max-width: 1130px) {
  .stack {
    .grid {
      display: block;
      width: fit-content;
      margin: 0 auto;
      height: unset;
    }

    .item-container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 20px;
      row-gap: 40px;
      margin-top: 80px;
      border-left: 2px solid #fffd77;
      padding-left: 20px;
    }
  }
}

@media screen and(max-width:580px) {
  .stack {
    height: unset;
    min-height: 100vh;
    padding: 40px 25px;
    padding-bottom: 80px;

    .grid {
      margin: 0;
      width: 100%;
    }

    .watermark {
      font-size: 50px;
    }
  }
}
