#gear_1,
#gear_2,
#gear_3,
#gear_4,
#gear_5,
#gear_6,
#gear_7 {
  animation: rotate 3s linear infinite;
  transform-box: fill-box;
  transform-origin: center;
}

#Body {
  animation: slide 0.36s linear infinite alternate;
  transform: translateX(-35px);
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}

@keyframes slide {
  to {
    transform: translateX(35px);
  }
}
