.section_heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 20px;
  letter-spacing: 0.08em;
  color: #fff;
}

.section_heading svg {
  width: 20px;
}
