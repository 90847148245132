#gear_1,
#gear_2,
#gear_3,
#gear_4,
#gear_5,
#gear_6,
#gear_7 {
  -webkit-animation: rotate 3s linear infinite;
          animation: rotate 3s linear infinite;
  transform-box: fill-box;
  -webkit-transform-origin: center;
          transform-origin: center;
}

#Body {
  -webkit-animation: slide 0.36s linear infinite alternate;
          animation: slide 0.36s linear infinite alternate;
  -webkit-transform: translateX(-35px);
          transform: translateX(-35px);
}

@-webkit-keyframes rotate {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotate {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes slide {
  to {
    -webkit-transform: translateX(35px);
            transform: translateX(35px);
  }
}

@keyframes slide {
  to {
    -webkit-transform: translateX(35px);
            transform: translateX(35px);
  }
}
