.item {
  border-radius: 50%;
  width: var(--size);
  height: var(--size);
  font-size: var(--font-size);
  position: absolute;
  background-color: #2f2f37;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
  text-align: center;

  &:hover {
    figure {
      opacity: 1;

      svg {
        transform: scale(1);
      }
    }
  }

  figure {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    background-color: #fffd77;
    transition: opacity 0.5s;
    border-radius: 50%;
    svg {
      width: 40px;
      transform: scale(0.7);
      transition: transform 0.5s;
    }
  }

  &:last-child figure svg {
    width: 30px;
  }
}

@media screen and(max-width: 1130px) {
  .item {
    position: static;
    flex-direction: column-reverse;
    justify-content: flex-end;
    text-transform: capitalize;
    cursor: initial;
    border-radius: 0;
    background-color: transparent;
    width: unset;
    height: unset;
    row-gap: 10px;

    figure {
      position: static;
      --size: 70px;
      width: var(--size);
      height: var(--size);
      background-color: #2f2f37;
      opacity: 1;
      transition: none;

      svg {
        transform: none;
        width: 25px;

        path {
          fill: #fff;
        }
      }
    }

    p {
      font-size: 14px;
      font-weight: 400;
    }
  }
}
