@import url("https://fonts.googleapis.com/css2?family=Bungee+Shade&display=swap");

.project {
  padding: 65px 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: max-content 1fr;
  column-gap: 60px;
  background-color: #3d3d52;
  border-radius: 5px;
  position: relative;
  margin-bottom: 200px;

  &::before {
    content: attr(data-index);
    font-size: 130px;
    line-height: 1em;
    position: absolute;
    z-index: -2;
    right: -40px;
    top: 0;
    transform: translateY(-90%);
    color: #6c6c7a;
    font-family: "Bungee Shade", cursive;
  }

  .image {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
    position: relative;
    z-index: 1;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($color: #000000, $alpha: 0.81);
      z-index: 2;
      transition: opacity 0.4s;
      opacity: 0;
    }

    img {
      width: 100%;
      transform: scale(1);
      transition: transform 0.4s;
    }
    svg {
      position: absolute;
      display: inline-block;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: opacity 0.4s;
      opacity: 0;
      z-index: 3;
      width: 30px;
    }

    &:hover {
      &::before {
        opacity: 1;
      }

      img {
        transform: scale(1.05);
      }

      svg {
        opacity: 1;
      }
    }
  }

  .top {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      color: #fff;
      font-size: 17px;
      font-weight: 700;
      text-transform: uppercase;
    }

    .icons {
      display: flex;
      align-items: center;
      gap: 20px;
      a {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      svg {
        width: 20px;
      }
    }
  }

  .description {
    grid-column: 2 / 3;
    grid-row: 2 / 3;

    & > p {
      color: #f0f0f4;
      margin-top: 15px;
    }

    .list_area {
      margin-top: 20px;
    }

    .grid {
      display: flex;
      column-gap: 20px;
      margin-bottom: 10px;
      font-size: 15px;

      .label {
        font-weight: 600;
        color: #fff;
      }

      .list {
        display: flex;
        flex-wrap: wrap;
        column-gap: 15px;
        row-gap: 8px;
        color: #b9b9cb;
        font-family: "Roboto Mono", monospace;
      }
    }
  }

  &:nth-child(even) {
    .image {
      grid-column: 2/ 3;
    }

    .top,
    .description {
      grid-column: 1 / 2;
    }

    &::before {
      right: unset;
      left: -40px;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

@media screen and(max-width: 800px) {
  .project {
    display: flex;
    padding: 30px;
    flex-direction: column;
    gap: 25px;
    margin-bottom: 55px;

    &::before {
      display: none;
    }

    .description {
      & > p {
        margin: 0;
      }
    }
  }
}

@media screen and(max-width:580px) {
  .project {
    padding: 20px 20px;
  }
}
