.archive {
  background-color: #181820;
  padding: 40px 70px;
  padding-bottom: 100px;
  position: relative;
  z-index: 1;
  min-height: 100vh;
  overflow: hidden;
}

.archive .inner {
  padding: 0 140px;
  margin-top: 70px;
}

.archive .inner .talk h1 {
  color: #fff;
  font-size: 27px;
  margin-bottom: 7px;
}

.archive .inner .talk h1 span {
  position: relative;
}

.archive .inner .talk h1 span svg {
  position: absolute;
  left: 1.5ch;
  bottom: 100%;
  width: 60%;
}

.archive .inner .talk p {
  color: #c5c5d3;
  font-size: 15px;
}

.archive .inner .tags {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-column-gap: 25px;
          column-gap: 25px;
  margin-top: 50px;
  overflow: auto;
}

.archive .inner .tags::-webkit-scrollbar {
  display: none;
}

.archive .inner .projects {
  margin-top: 70px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr;
  -webkit-column-gap: 60px;
          column-gap: 60px;
  row-gap: 70px;
}

.archive .watermark {
  position: absolute;
  left: -30px;
  font-size: 100px;
  color: #1d1d26;
  font-weight: 700;
  width: 500px;
  line-height: 1em;
  top: 195px;
  z-index: -1;
  pointer-events: none;
}

@media screen and (max-width: 1280px) {
  .archive .inner {
    padding: 0;
  }
}

@media screen and (max-width: 1170px) {
  .archive .inner .projects {
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
    -webkit-column-gap: 80px;
            column-gap: 80px;
  }
}

@media screen and (max-width: 775px) {
  .archive .inner .projects {
    -webkit-column-gap: 60px;
            column-gap: 60px;
  }
}

@media screen and (max-width: 700px) {
  .archive .inner .tags {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .archive .inner .projects {
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    margin-top: 50px;
  }
}

@media screen and (max-width: 580px) {
  .archive {
    padding: 25px;
    padding-bottom: 60px;
  }
}
