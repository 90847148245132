.section_heading {
  display: flex;
  gap: 10px;
  align-items: center;
  font-size: 20px;
  letter-spacing: 0.08em;
  color: #fff;

  svg {
    width: 20px;
  }
}
