.alert{
    width: 350px;
    padding: 45px 10px;
    text-align: center;
    color: #fff;
    background-color: #2C2C3A;
    border-radius: 8px;
    font-weight: 600;
    position: fixed;
    --top:: 40px;
    top: var(--top);
    left: 50%;
    transform: translate(-50%, calc(-1 * (var(--top) + 100%)));
    transition: transform 0.3s;
    z-index: 8;

    button{
        all: unset;
        box-sizing: border-box;
        cursor: pointer;
        position: absolute;
        top: 15px;
        right: 20px;

        svg{
            width: 15px;
        }
    }

    &.active{
        transform: translate(-50%, 0);
    }
}

@media screen and(max-width:450px) {
    .alert{
        width: 80%;
        padding: 30px 10px;


        button{
            top: 10px;
            right: 15px;
        }
    }
}