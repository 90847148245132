@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;800;900&family=Roboto+Mono:wght@400;500;600&display=swap");

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Poppins", sans-serif;
}

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 13px;
  border-radius: 15px;
}

::-webkit-scrollbar-track {
  background-color: rgb(32, 35, 36);
}

::-webkit-scrollbar-thumb {
  background-color: rgb(69, 74, 77);
  border-radius: 15px;
}

// Hero
@import "./styles/hero";

// About
@import "./styles/about";

// Services
@import "./styles/services";

// Stack
@import "./styles/stack";

// Projects
@import "./styles/projects";

// Contact
@import "./styles/contact";

// Footer
@import "./styles/footer";
