.item {
  list-style-type: none;
  color: #dcdce5;
  opacity: 0;
  position: absolute;
  left: 0;
  margin:2px 7px;
  width: 100%;
  height: auto;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.item.active {
  opacity: 1;
}

@media screen and (max-width: 830px) {
  .item {
    position: static;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    width: 100%;
  }
}
