.nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.nav .toggle {
  position: relative;
  z-index: 5;
  display: none;
}

.nav svg {
  width: 50px;
  height: auto;
  position: relative;
  z-index: 5;
}

.nav ul {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 20px;
}

.nav ul a {
  all: unset;
  cursor: pointer;
  color: #f1f1f3;
  font-size: 13px;
  -webkit-transition: color 0.4s, -webkit-transform 0.4s;
  transition: color 0.4s, -webkit-transform 0.4s;
  transition: color 0.4s, transform 0.4s;
  transition: color 0.4s, transform 0.4s, -webkit-transform 0.4s;
  display: inline-block;
}

.nav ul:hover a {
  color: #838395;
}

.nav ul:hover a:hover {
  color: #fff;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

@media screen and (max-width: 730px) {
  .nav svg {
    width: 40px;
  }
  .nav .toggle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 6px;
    width: 30px;
    cursor: pointer;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
  }
  .nav .toggle span, .nav .toggle::before, .nav .toggle::after {
    background-color: #fff;
    height: 3px;
    display: inline-block;
    -webkit-transition: -webkit-transform 0.4s;
    transition: -webkit-transform 0.4s;
    transition: transform 0.4s;
    transition: transform 0.4s, -webkit-transform 0.4s;
  }
  .nav .toggle span {
    width: 100%;
  }
  .nav .toggle::before, .nav .toggle::after {
    content: "";
    -webkit-transform: scaleX(0.4);
            transform: scaleX(0.4);
  }
  .nav .toggle::before {
    -webkit-transform-origin: right;
            transform-origin: right;
  }
  .nav .toggle::after {
    -webkit-transform-origin: left;
            transform-origin: left;
  }
  .nav ul {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #0f0f14;
    z-index: 4;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-top: 130px;
    -webkit-transform: translateY(-110%);
            transform: translateY(-110%);
    opacity: 0;
    -webkit-transition: opacity 0.4s, -webkit-transform 0.4s;
    transition: opacity 0.4s, -webkit-transform 0.4s;
    transition: transform 0.4s, opacity 0.4s;
    transition: transform 0.4s, opacity 0.4s, -webkit-transform 0.4s;
    gap: 25px;
  }
  .nav ul a {
    color: #b0b0bf;
    font-size: 15px;
  }
  .nav ul:hover a {
    color: #b0b0bf;
  }
  .nav.active .toggle span {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
  }
  .nav.active .toggle::before {
    -webkit-transform: translate(-3px, 27px) scaleX(1) rotate(45deg);
            transform: translate(-3px, 27px) scaleX(1) rotate(45deg);
  }
  .nav.active .toggle::after {
    -webkit-transform: translate(5px, 9px) scaleX(1) rotate(-45deg);
            transform: translate(5px, 9px) scaleX(1) rotate(-45deg);
  }
  .nav.active ul {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
