@import url('https://fonts.googleapis.com/css2?family=SF+Pro+Display:wght@400;500;600;700&display=swap');

.supportItem {
  font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, sans-serif;
  padding: 40px;
  display: flex;
  flex-direction: column;
  background-color: #181820;
  color: white;
  border-radius: 20px;
  position: relative;
  margin-bottom: 60px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.supportItem .top {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  position: relative;
}

.supportItem .mainImage {
  width: 120px;
  height: 120px;
  border-radius: 20px;
  overflow: hidden;
  margin-right: 30px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.supportItem .mainImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.supportItem .titleArea {
  flex-grow: 1;
}

.supportItem .titleArea h2 {
  color: white;
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 10px;
}

.supportItem .icons {
  display: flex;
  gap: 15px;
}

.supportItem .icon {
  width: 24px;
  height: 24px;
  color: #06c;
  transition: color 0.3s ease;
}

.supportItem .icon:hover {
  color: #007aff;
}

.supportItem .statusBadge {
  position: absolute;
  top: 0;
  right: 0;
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

.supportItem .statusBadge.opened {
  background-color: #34c759;
  color: #ffffff;
}

.supportItem .statusBadge.closed {
  background-color: #ff3b30;
  color: #ffffff;
}

.supportItem .content {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.supportItem .progress p {
  color: #1d1d1f;
  font-weight: 600;
  margin-bottom: 10px;
}

.supportItem .progressBar {
  height: 6px;
  background-color: #34c759;
  border-radius: 3px;
}

.supportItem .supporters p {
  color: #1d1d1f;
  font-weight: 600;
  margin-bottom: 15px;
}

.supportItem .supportersList {
  display: flex;
  gap: 20px;
}

.supportItem .supporter {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.supportItem .supporter img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 3px solid #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.supportItem .supporter span {
  color: #86868b;
  font-size: 14px;
}

.supportItem .details {
  font-size: 16px;
}

.supportItem .items {
  margin-top: 40px;
}

.supportItem .items h3 {
  font-size: 24px;
  margin-bottom: 20px;
}

.supportItem .items ul {
  list-style-type: none;
  padding: 0;
}

.supportItem .items li {
  display: flex;
  background-color: #181820;
  padding: 20px;
  border-radius: 15px;
  margin-bottom: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.supportItem .items li:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.supportItem .itemImage {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  overflow: hidden;
  margin-right: 20px;
}

.supportItem .itemImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.supportItem .itemContent {
  flex-grow: 1;
}

.supportItem .items h4 {
  font-size: 20px;
  margin-bottom: 10px;
}

.supportItem .items p {
  margin-bottom: 5px;
}

.supportItem .items .cost {
  color: #06c;
  font-weight: 600;
}

.supportItem .items .date {
  font-size: 14px;
}

@media screen and (max-width: 800px) {
  .supportItem {
    padding: 30px;
  }
  .supportItem .top {
    flex-direction: column;
    align-items: flex-start;
  }
  .supportItem .mainImage {
    margin-bottom: 20px;
  }
  .supportItem .items li {
    flex-direction: column;
  }
  .supportItem .itemImage {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 580px) {
  .supportItem {
    padding: 20px;
  }
  .supportItem .supportersList {
    flex-wrap: wrap;
  }
}