.slider_item {
  --size: 90px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  font-weight: 700;
  font-size: 12px;
  color: #fff;
  background-color: #4f4f69;
  position: absolute;
  cursor: pointer;
  -webkit-transition: -webkit-transform 0.4s;
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
}

.slider_item.active {
  background-color: #7c99df;
}
