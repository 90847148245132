.alert {
  width: 350px;
  padding: 45px 10px;
  text-align: center;
  color: #fff;
  background-color: #2C2C3A;
  border-radius: 8px;
  font-weight: 600;
  position: fixed;
  --top: 40px;
  top: var(--top);
  left: 50%;
  -webkit-transform: translate(-50%, calc(-1 * (var(--top) + 100%)));
          transform: translate(-50%, calc(-1 * (var(--top) + 100%)));
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  z-index: 8;
}

.alert button {
  all: unset;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 20px;
}

.alert button svg {
  width: 15px;
}

.alert.active {
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
}

@media screen and (max-width: 450px) {
  .alert {
    width: 80%;
    padding: 30px 10px;
  }
  .alert button {
    top: 10px;
    right: 15px;
  }
}
