.info {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 20px 1fr;
      grid-template-columns: 20px 1fr;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  -webkit-column-gap: 15px;
          column-gap: 15px;
}

.info svg {
  width: 22px;
  height: 22px;
}

.info .value_container {
  height: 100%;
  overflow: hidden;
}

.info:hover .value {
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
}

.info .value {
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-transform: translateY(0%);
          transform: translateY(0%);
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.info .value p {
  position: absolute;
  left: 0;
  top: 0;
  line-height: 1em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
}

.info .value p:last-child {
  color: #fffd77;
  top: 100%;
}
