.skeleton {
  padding: 65px 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: max-content 1fr;
  column-gap: 60px;
  background-color: #3d3d52;
  border-radius: 5px;
  margin-bottom: 120px;

  .image {
    grid-column: 1 / 2;
    grid-row: 1 / 3;
  }

  & > .top {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
  }

  .description {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    margin-top: 20px;

    .top {
      margin-bottom: 20px;
    }
  }
}

@media screen and(max-width: 800px) {
  .skeleton {
    display: flex;
    padding: 30px;
    flex-direction: column;
    gap: 25px;
    margin-bottom: 55px;

    .description {
      margin-top: 0;
    }
  }
}

@media screen and(max-width:580px) {
  .skeleton {
    padding: 30px 20px;
  }
}
