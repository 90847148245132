@import url("https://fonts.googleapis.com/css2?family=Bungee+Shade&display=swap");
.error {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  height: 100vh;
  overflow: hidden;
  position: relative;
  --padding: 70px;
}

.error .logo {
  position: absolute;
  right: var(--padding);
  top: 50px;
}

.error .logo svg {
  width: 60px;
  height: auto;
}

.error .talk {
  background-color: #181820;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  row-gap: 25px;
  padding: 0 var(--padding);
}

.error .talk h1,
.error .talk p,
.error .talk a {
  position: relative;
  z-index: 2;
}

.error .talk h1 {
  font-family: "Bungee Shade", cursive;
  color: #f0f0f4;
  font-size: 100px;
  font-weight: 400;
  line-height: 1em;
}

.error .talk p {
  color: #fff;
  white-space: pre-wrap;
  max-width: 400px;
}

.error .talk p b {
  word-spacing: 3px;
}

.error .talk a {
  width: 140px;
  text-align: center;
}

.error .robot {
  padding: 0 var(--padding);
  background-image: linear-gradient(129.9deg, #484860 0.21%, #111117 97.35%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.error .robot svg {
  max-width: 380px;
}

.circle {
  position: absolute;
  left: -20px;
  bottom: -50px;
  width: 300px;
  height: auto;
}

@media screen and (max-width: 890px) {
  .error {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    background-image: linear-gradient(129.9deg, #484860 0.21%, #111117 97.35%);
    padding: 35px;
    row-gap: 40px;
    padding-top: 80px;
  }
  .error .logo {
    position: static;
  }
  .error .talk {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .error .talk a {
    width: 100%;
  }
  .error .talk h1 {
    font-size: 60px;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
  }
  .error .talk p {
    font-size: 15px;
  }
  .error .talk,
  .error .robot {
    background-color: transparent;
    background-image: none;
    padding: 0;
  }
  .error .robot {
    display: none;
  }
}

@media screen and (max-width: 760px) {
  .error .circle {
    display: none;
  }
}
