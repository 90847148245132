.button {
  all: unset;
  display: inline-block;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  cursor: pointer;
  padding: 8px 25px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.button:hover {
  -webkit-transform: translateX(10%);
          transform: translateX(10%);
}
