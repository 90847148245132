.slider {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  --size: 450px;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  position: relative;
}

.slider svg {
  position: absolute;
  right: calc(var(--size) / 2);
  top: 50%;
  -webkit-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
  display: inline-block;
  width: 100px;
  height: auto;
}

.slider ul {
  -webkit-transition: -webkit-transform 0.4s;
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
  list-style-type: none;
  position: relative;
  width: var(--size);
  height: var(--size);
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='50%25' ry='50%25' stroke='%235E5E7DFF' stroke-width='2' stroke-dasharray='35' stroke-dashoffset='80' stroke-linecap='butt'/%3e%3c/svg%3e");
}

@media screen and (max-width: 650px) {
  .slider {
    display: none;
  }
}
