.info {
  display: grid;
  grid-template-columns: 20px 1fr;
  align-items: center;
  cursor: pointer;
  column-gap: 15px;

  svg {
    width: 22px;
    height: 22px;
  }

  .value_container {
    height: 100%;
    overflow: hidden;
  }

  &:hover {
    .value {
      transform: translateY(-100%);
    }
  }

  .value {
    position: relative;
    width: 100%;
    height: 100%;
    transform: translateY(0%);
    transition: transform 0.3s;

    p {
      position: absolute;
      left: 0;
      top: 0;
      line-height: 1em;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;

      &:last-child {
        color: #fffd77;
        top: 100%;
      }
    }
  }
}
