.contact {
  background-color: #181820;
  padding: 80px 70px;
  position: relative;

  .grid {
    margin-top: 15px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 40px;
    color: #b9b9cb;

    .info {
      & > p {
        max-width: 380px;
      }

      .info-items {
        margin-top: 35px;
        display: flex;
        flex-direction: column;
        row-gap: 20px;
      }
    }

    form {
      .form-grid {
        all: unset;
        box-sizing: border-box;
        display: grid;
        grid-template-columns: max-content max-content;
        grid-template-rows: 25px 1fr;
        column-gap: 60px;
        row-gap: 40px;

        div:last-child {
          grid-column: 1 / 3;
        }
      }

      button {
        margin-top: 40px;
        padding: 8px 50px;
      }
    }
  }

  .position {
    position: absolute;
    left: 30px;
    bottom: 0;
    transform: translateY(50%);
    width: 220px;
  }
}

@media screen and(max-width:900px) {
  .contact {
    .inner {
      width: fit-content;
      margin: 0 auto;
    }
    .grid {
      grid-template-columns: 1fr;
      row-gap: 100px;
    }

    .position {
      display: none;
    }
  }
}

@media screen and(max-width:580px) {
  .contact {
    padding: 80px 25px;

    .grid form .form-grid {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 25px 25px 1fr;
      row-gap: 70px;
      div {
        grid-column: 1 / 3;
      }
    }
  }
}
