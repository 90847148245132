.slider_item {
  --size: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  font-weight: 700;
  font-size: 12px;
  color: #fff;
  background-color: #4f4f69;
  position: absolute;
  cursor: pointer;
  transition: transform 0.4s;

  &.active {
    background-color: #7c99df;
  }
}
