.archive {
  background-color: #181820;
  padding: 40px 70px;
  padding-bottom: 100px;
  position: relative;
  z-index: 1;
  min-height: 100vh;
  overflow: hidden;

  .inner {
    padding: 0 140px;
    margin-top: 70px;

    .talk {
      h1 {
        color: #fff;
        font-size: 27px;
        margin-bottom: 7px;

        span {
          position: relative;

          svg {
            position: absolute;
            left: 1.5ch;
            bottom: 100%;
            width: 60%;
          }
        }
      }

      p {
        color: #c5c5d3;
        font-size: 15px;
      }
    }

    .tags {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 25px;
      margin-top: 50px;
      overflow: auto;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .projects {
      margin-top: 70px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 60px;
      row-gap: 70px;
    }
  }

  .watermark {
    position: absolute;
    left: -30px;
    font-size: 100px;
    color: #1d1d26;
    font-weight: 700;
    width: 500px;
    line-height: 1em;
    top: 195px;
    z-index: -1;
    pointer-events: none;
  }
}

@media screen and(max-width:1280px) {
  .archive .inner {
    padding: 0;
  }
}

@media screen and(max-width:1170px) {
  .archive .inner {
    .projects {
      grid-template-columns: 1fr 1fr;
      column-gap: 80px;
    }
  }
}

@media screen and(max-width:775px) {
  .archive .inner {
    .projects {
      column-gap: 60px;
    }
  }
}

@media screen and(max-width:700px) {
  .archive .inner {
    .tags {
      justify-content: flex-start;
    }

    .projects {
      grid-template-columns: 1fr;
      margin-top: 50px;
    }
  }
}

@media screen and(max-width:580px) {
  .archive {
    padding: 25px;
    padding-bottom: 60px;
  }
}
