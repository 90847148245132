.project {
  background-color: #272734;
  border-radius: 5px;
  padding: 15px 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.project .title {
  color: #fff;
  font-weight: 700;
  font-size: 17px;
  margin-bottom: 12px;
}

.project .description {
  color: #b6b6c8;
  font-size: 15px;
  margin-bottom: 15px;
}

.project .icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 12px;
}

.project .icons a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.project .icons svg {
  width: 17px;
}
