.tag {
  font-size: 14px;
  display: inline-block;
  padding: 6px 20px;
  text-align: center;
  border: 1px solid #303040;
  border-radius: 50px;
  color: #fff;
  min-width: 80px;
  cursor: pointer;
  flex-shrink: 0;

  &.active {
    font-weight: 600;
    background-color: #303040;
    border: none;
  }
}
