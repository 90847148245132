.skeleton {
  padding: 65px 40px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  -ms-grid-rows: max-content 1fr;
      grid-template-rows: -webkit-max-content 1fr;
      grid-template-rows: max-content 1fr;
  -webkit-column-gap: 60px;
          column-gap: 60px;
  background-color: #3d3d52;
  border-radius: 5px;
  margin-bottom: 120px;
}

.skeleton .image {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-column: 1 / 2;
  -ms-grid-row: 1;
  -ms-grid-row-span: 2;
  grid-row: 1 / 3;
}

.skeleton > .top {
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2 / 3;
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  grid-row: 1 / 2;
}

.skeleton .description {
  -ms-grid-column: 2;
  -ms-grid-column-span: 1;
  grid-column: 2 / 3;
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  grid-row: 2 / 3;
  margin-top: 20px;
}

.skeleton .description .top {
  margin-bottom: 20px;
}

@media screen and (max-width: 800px) {
  .skeleton {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 25px;
    margin-bottom: 55px;
  }
  .skeleton .description {
    margin-top: 0;
  }
}

@media screen and (max-width: 580px) {
  .skeleton {
    padding: 30px 20px;
  }
}
