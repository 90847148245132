.skeleton {
  background-color: hsl(240, 14%, 18%);
  border-radius: 5px;
  padding: 15px 18px;

  & > div {
    &:nth-child(2) {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    &:last-child {
      width: 40%;
    }
  }
}
