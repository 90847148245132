.container {
  textarea {
    all: unset;
    box-sizing: border-box;
    cursor: text;
    border: 1px solid #fff;
    border-radius: 5px;
    color: #fff;
    display: block;
    width: 100%;
    padding: 8px 10px;
    min-height: 120px;
    &:focus {
      border-color: #fffd77;
    }
  }

  label {
    display: inline-block;
    margin-bottom: 12px;
    color: #b9b9cb;
  }
}
