.project {
  background-color: #272734;
  border-radius: 5px;
  padding: 15px 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .title {
    color: #fff;
    font-weight: 700;
    font-size: 17px;
    margin-bottom: 12px;
  }

  .description {
    color: #b6b6c8;
    font-size: 15px;
    margin-bottom: 15px;
  }

  .icons {
    display: flex;
    align-items: center;
    gap: 12px;
    a {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    svg {
      width: 17px;
    }
  }
}
